import React from "react"
import {
  translate,
  translateHtml,
  stringBasedArrayIndices,
} from "../helpers/grammar"
import SEO from "../components/seo"
import { useIntl } from "react-intl"
import Hero from "../components/hero"
import FAQs from "../components/faqs"
import Stats from "../components/stats"
import Process from "../components/process"
import Columned from "../components/columned"
import MainLayout from "../components/layouts/main"
import heroImage from "../images/home/home-hero@2x.jpg"

/**
 * Component rendering the "/" page of the site.
 *
 * @returns {JSX.Element}
 */
const IndexPage = (): JSX.Element => {
  const intl = useIntl()

  // Used for passing previous route when the user
  // goes to "/forms" page
  const routeId = "homepage"

  // Used for "HeroText" (inside "Hero") and
  // "FixedCTA" (inside "Footer") components.
  const pageTitle = "homepage"

  // Translate text content for the page
  const columnedData = stringBasedArrayIndices(4).map(item => ({
    title: translate(intl, `homepage.weAreHereToHelp.${item}.title`),
    text: translate(intl, `homepage.weAreHereToHelp.${item}.text`),
  }))
  const processData = stringBasedArrayIndices(4).map(item => ({
    title: translate(intl, `homepage.process.${item}.title`),
    text: translate(intl, `homepage.process.${item}.text`),
  }))
  const statsData = stringBasedArrayIndices(3).map(item => ({
    preText:
      item !== "second"
        ? translate(intl, `homepage.stats.${item}.preText`)
        : "",
    main: translate(intl, `homepage.stats.${item}.main`),
    text: translate(intl, `homepage.stats.${item}.text`),
  }))
  const faqsData = stringBasedArrayIndices(3).map((item, i) => ({
    title: translate(intl, `homepage.faqs.${item}.title`),
    questions: stringBasedArrayIndices(i === 2 ? 5 : 3).map((value, k) => ({
      question: translate(
        intl,
        `homepage.faqs.${item}.questions.${k}.question`
      ),
      answer: translateHtml(
        intl,
        `homepage.faqs.${item}.questions.${k}.answer`
      ),
    })),
  }))

  // Render the component using "MainLayout"
  return (
    <MainLayout routeId={routeId} pageTitle={pageTitle} hasCTA={true}>
      <SEO title={translate(intl, "homepage.title")} />
      <Hero routeId={routeId} image={heroImage} pageTitle={pageTitle} />
      <div id="learnMore" />
      <Columned
        data={columnedData}
        title={translate(intl, `homepage.weAreHereToHelp.title`)}
      />
      <Process
        data={processData}
        title={translate(intl, `homepage.process.title`)}
      />
      <Stats data={statsData} />
      <FAQs data={faqsData} title={translate(intl, `homepage.faqs.title`)} />
    </MainLayout>
  )
}

export default IndexPage
